import { request, proRequest } from '@/utils/request.js'
import { transformProduct,transformComment } from '@/utils/transform';

// 创建产品提问
export function createCaseComment(id, data) {
    return request.post(`/cases/${id}/comments`, data)
}

// 获取产品提问
export function getCaseComment(id, params) {
    return request.get(`/cases/${id}/comments`, { params }).then(({ data }) => {
        let temp = {}, rows;
        rows = data.rows || [];

        temp.count = data.count;
        temp.rows = rows.map(row => transformComment(row))
        return Promise.resolve(temp)
    })
}

// 获取案例列表
export function getCases(params) {
    return request.get('/cases', { params }).then(({ data }) => {
        let temp = {}, rows;
        rows = data.rows || [];

        temp.count = data.count;
        temp.rows = rows.map(row => transformProduct(row))
        return Promise.resolve(temp)
    })
}

// 获取当前公司案例列表
export function getCurrentCases() {
    return request.get('/user/company/cases')
}

// 获取当前公司案例详情
export function getCurrentCaseDetail(id) {
    return request.get(`/user/company/cases/${id}`)
}

// 获取公司案例列表
export function getCompanyCases(id) {
    return request.get(`/company/${id}/cases`)
}

// 获取案例详情
export function getCase(id) {
    return request.get(`/cases/${id}`).then(({ data }) => {
        return Promise.resolve(transformProduct(data))
    })
}

// 设置案例可见性
export function setCaseVisible(id,data) {
    return request.post(`/cases/${id}/visible`,data)
}

// 创建案例
export function createCase(data) {
    return request.post(`/cases`, data)
}

// 保存案例
export function saveCase(data) {
    return request.put(`/cases/${data.ID}`, data)
}

// 删除案例
export function deleteCase(id) {
    return request.delete(`/cases/${id}`)
}

// 提交案例
export function evaluateCase(id) {
    return request.post(`/cases/${id}/submit`)
}

// 获取后台案例审核列表
export function getProCases(params) {
    return proRequest.get(`/cases`, { params })
}

// 获取企业案例审核列表
export function getProCompanyCases(id) {
    return proRequest.get(`/company/${id}/cases`)
}

// 获取后台案例详情
export function getProCaseDetail(id) {
    return proRequest.get(`/cases/${id}`)
}

// 保存后台案例
export function saveProCase(id, data) {
    return proRequest.put(`/cases/${id}`, data)
}

// 审核后台案例
export function evaluteProCase(id, data) {
    return proRequest.post(`/cases/${id}/audit`, data)
}
